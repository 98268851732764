<template>
<div>
    <div class="container">
        <div class="row">
            <div class="col-lg-12 staff-person" v-for="(person, index) in administrationPeople" :key="index">
                <div class="row">
                    <div class="col-lg-4">
                        <h4>{{person.attributes.field_first_name}} {{person.attributes.field_person_last_name}}</h4>
                        <p>{{person.attributes.field_position}}</p>
                    </div>
                    <div class="col-lg-4 d-flex align-items-center">
                        <a :href="'mailto:'+person.attributes.field_person_email"><img src="@/assets/icon-mail.svg" />{{person.attributes.field_person_email}}</a>
                    </div>
                    <div class="col-lg-4 d-flex align-items-center" v-if="person.attributes.field_person_phone">
                        <p class="phone"><img src="@/assets/icon-phone.svg" />{{person.attributes.field_person_phone}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="administration">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 staff-person" v-for="(person, index) in staffPeople" :key="index">
                    <div class="row">
                        <div class="col-lg-4">
                            <h4>{{person.attributes.field_first_name}} {{person.attributes.field_person_last_name}}</h4>
                            <p>{{person.attributes.field_position}}</p>
                        </div>
                        <div class="col-lg-4 d-flex align-items-center">
                            <a :href="'mailto:'+person.attributes.field_person_email"><img src="@/assets/icon-mail.svg" />{{person.attributes.field_person_email}}</a>
                        </div>
                        <div class="col-lg-4 d-flex align-items-center" v-if="person.attributes.field_person_phone">
                            <p class="phone"><img src="@/assets/icon-phone.svg" />{{person.attributes.field_person_phone}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
export default {
    name:"people-staff",
    computed:{
        people(){
            return this.$store.state.people;
        },
        administrationPeople(){
            return this.filteredPeople.filter(item => {
                return item.attributes.field_position.toLowerCase().indexOf("administration")!==-1
            });
        },
        staffPeople(){
            return this.filteredPeople.filter(item => {
                return item.attributes.field_position.toLowerCase().indexOf("administration")===-1
            });
        },
        filteredPeople(){
            let staffPeople;
            staffPeople = this.people.filter(item => {
                return item.attributes.field_person_role.includes("staff")
            })

            /* sort alphabetically*/
            staffPeople = staffPeople.sort((a,b)=>{
                if (a.attributes.field_person_last_name < b.attributes.field_person_last_name) { return -1; }
                if (a.attributes.field_person_last_name > b.attributes.field_person_last_name) { return 1; }
                return 0;
            });
 

            /*
            let staffPeopleXS = this.people.filter(item => {
                let a;
                if(item.attributes.field_position){
                    a = item.attributes.field_position.includes("Administration")
                }
                return a
            })

             */
            /*
            sorted.sort
            */

            return staffPeople
        },
    },
    mounted(){
        this.$store.dispatch('LOAD_PEOPLE');
    }
};
</script>
<style lang="scss" scoped>
.administration{
    background: hsl(0, 0%, 97%);
}
.staff-person>.row > div {
    align-items: center;
}
.staff-person{
    position: relative;
    padding-left: 49px;
    border-bottom: 1px solid #EBEBEB;
    padding-top: 2rem;

    &:before{
        content: "";
        left: 0;
        background: url("../assets/list-point-green.png");
        width: 27px;
        height: 40px;
        background-size: cover;
        position: absolute;
    }
    h4 {
        line-height: 1.8rem;
        color: #293446;
        font-size: 1.25rem  ;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 0;
    }
    p img,
    a img{
        position: relative;
        top: 0px;
        left: -10px;
    }
    a {
        padding-left: 20px;
    }
}

@media (max-width:992px){
    .staff-person {
        padding-bottom: 32px;
        a {
            padding-left: 10px;
        }
        p {
            margin-bottom: 0.25rem;
        }
        .phone {
            padding-left: 10px;
            margin-top: 10px;
        }
    }
}

@media (max-width:576px){
    .staff-person>.row > div {
        padding-top: 0px;
        padding-bottom: 7px;
    }
    .staff-person {
        padding-left: 30px;
        margin-bottom: 1rem;
        padding-bottom: 10px;
    }
    .staff-person p {
        line-height: 1.5rem;
    }
}


</style>