<template>
    <div class="staff">
        <div class="small-intro bg-img">
            <intro-page :pageId="currentPageId"></intro-page>
        </div>
        <people-staff class="staff-people" ></people-staff>
    </div>
</template>
<script>
import IntroPage from '../components/IntroPage.vue'
import PeopleStaff from '../components/PeopleStaff.vue'

export default {
    name:"staff",
    components: {
        IntroPage,
        PeopleStaff
    },
    data(){
        return {
            currentPageId:"6d6b2bbf-e270-4bd0-889b-e8c72d56cbd8",
        }
    },
    methods:{
    },
};

</script>

<style lang="scss">
.staff .bg-img{
    background-image: url(/img/staff-intro.4d24df39.png);
    background-size: 30%;
    background-position: 90% 43%;
}
.staff-people >.row{
    margin: 0rem 0rem 4rem 0rem;
}

@media (max-width:576px) {
    .staff .bg-img {
        background-size: 50%;
    }
    .staff-person {
    padding-left: 20px;
    }
    .staff-person a {
        padding-left: 10px;
        word-break: break-all;
    }
    .staff-person:before{
        transform: scale(0.8);
        transform-origin: left;
    }
    .staff-person > .row > div {
        padding-right: 0;
    }
}
</style>